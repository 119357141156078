<template>
  <v-container fluid fill-height>
    <v-row align="center" justify="center">
      <v-col>
        <div class="text-center mb-3">
          <v-avatar size="150">
            <img alt="user" src="/images/Icon.png" />
          </v-avatar>
        </div>
        <v-card class="mx-auto mb-3" max-width="500">
          <v-list>
            <v-subheader>Choose the language</v-subheader>
            <v-list-item-group>
              <v-list-item
                v-for="(item, i) in locales"
                :key="i"
                @click.stop="changeLocale(item)"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
export default {
  data: () => ({
    locales: [
      {
        name: "Bahasa Melayu",
        label: "BM",
        value: "ms",
      },
      {
        name: "中文",
        label: "中文",
        value: "zh",
      },
      {
        name: "English",
        label: "EN",
        value: "en",
      },
    ],
  }),
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  // mounted() {
  //   if (this.$route.query.code) {
  //     if (this.$route.query.code.substring(0, 9) != this.auth.School.code) {
  //       this.$router.push({
  //         name: "PageMainLogout",
  //         query: { code: this.$route.query.code.substring(0, 9) },
  //       });
  //     }
  //   } else {
  //     this.$router.push({ name: "PageMainLogout" });
  //   }
  // },
  created() {
    const query = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    if (query.lang) {
      var index = this.$_.findIndex(this.locales, function (locale) {
        return locale.value == query.lang;
      });
      if (index) {
        this.changeLocale(this.locales[index]);
      }
    }
  },
  methods: {
    changeLocale(locale) {
      var href = this.removeURLParameter(window.location.href, "lang");
      var query = href.split("?")[1] ? `?${href.split("?")[1]}` : "";

      if (this.auth && this.auth.Teacher) {
        window.location.href = `${window.location.href.split("?")[0]}${
          locale.value
        }/island/classroom${query}`;
      } else {
        if (
          this.auth &&
          this.auth.School &&
          this.auth.School.code &&
          this.$route.query &&
          this.$route.query.code &&          
          this.$route.query.code.substring(0, 9) != this.auth.School.code
        ) {
          window.location.href = `${window.location.href.split("?")[0]}${
            locale.value
          }/logout${query}`;
        } else {
          window.location.href = `${window.location.href.split("?")[0]}${
            locale.value
          }/landing${query}`;
        }
      }
    },
    removeURLParameter(url, parameter) {
      //refer to https://stackoverflow.com/a/26257722
      var urlparts = url.split("?");
      if (urlparts.length >= 2) {
        var prefix = encodeURIComponent(parameter) + "=";
        var pars = urlparts[1].split(/[&;]/g);
        for (var i = pars.length; i-- > 0; ) {
          if (pars[i].lastIndexOf(prefix, 0) !== -1) {
            pars.splice(i, 1);
          }
        }
        url = urlparts[0] + "?" + pars.join("&");
        return url;
      } else {
        return url;
      }
    },
  },
};
</script>
